import type { InputRef } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-utils';
import { EgGridModel, ImgFormatter, request } from 'egenie-utils';
import * as _ from 'lodash';
import { action, observable } from 'mobx';
import React from 'react';
import { getAndPlayVoice, playVoice, voiceData } from '../../utils';
import type { CheckPassData, ProductData, SkuDetail } from './interface';

const Status = [
  {
    name: '未验货',
    color: '',
  },
  {
    name: '验货中',
    color: '#fff1dd',
  },
  {
    name: '验货通过',
    color: '#d9f6ef',
  },
];

export class Store {
  constructor() {
    this.getOrderCount();
  }

  public gridModel: EgGridModel = new EgGridModel({
    columns: [
      {
        key: 'status',
        name: '状态',
        width: 88,
        formatter: ({ row }) => (
          <div style={{
            backgroundColor: Status[row.status]?.color,
            paddingLeft: '8px',
          }}
          >
            {Status[row.status]?.name}
          </div>
        ),
      },
      {
        key: 'checkedNum',
        name: '已验数量',
      },
      {
        key: 'num',
        name: '数量',
      },
      {
        key: 'pic',
        name: '图片',
        width: 90,
        formatter: ({ row }) => (
          <ImgFormatter value={row.pic}/>
        ),
      },
      {
        key: 'skuNo',
        name: 'sku编码',
        width: 200,
        sortable: true,
      },
      {
        key: 'color',
        name: '颜色',
        sortable: true,
      },
      {
        key: 'size',
        name: '尺码',
        width: 120,
        sortable: true,
      },
    ],
    rows: [],
    rowHeight: 34,
    primaryKeyField: 'gmsGoodsId',
    edgStyle: {
      height: '100%',
      flexDirection: 'column',
    },
    sortByLocal: false,
    pageSize: 20,
    showCheckBox: false,
    showReset: false,
    showPager: false,
    showRefresh: false,
  });

  @observable public uniqueCode = '';

  @action public handleUniqueCodeChange = (e) => {
    this.uniqueCode = e.target.value;
  };

  public uniqueCodeRef: React.RefObject<InputRef> = React.createRef<InputRef>();

  @observable public courierNo = '';

  @action public handleCourierNoChange = (e) => {
    this.courierNo = e.target.value;
  };

  public courierRef: React.RefObject<InputRef> = React.createRef<InputRef>();

  @observable public checkDisabled = true;

  @observable public totalOrder = 0;

  @observable public resetOrder = 0;

  @observable public isCheckSuccess = true; // 快递单号校验成功

  @observable public errorInfo = '';

  @observable public productData: ProductData = {};

  @observable public currentData: SkuDetail = {};

  @observable public uniqueNos: string[] = []; // 唯一码

  @observable public notUniqueNos: string[] = []; // 条形码，商品编码

  // 待验货单数
  @action public getOrderCount = () => {
    request<BaseData<number>>({ url: '/api/cloud/wms/rest/order/check/count' })
      .then((res: { status: string; data: number; }) => {
        this.totalOrder = res.data || 0;
        this.resetOrder = res.data || 0;
      });
  };

  @observable public displayCourierNo = '';

  // 扫描快递单号
  @action public queryTrackingNumber = () => {
    const data = { wayBillNo: this.courierNo };
    this.displayCourierNo = this.courierNo;
    this.courierNo = '';
    this.courierRef.current?.focus();

    if (!data.wayBillNo) {
      this.handleError('快递单号不能为空', '请输入快递单号');
      this.checkDisabled = true;
      return;
    }

    request<BaseData<ProductData>>({
      url: '/api/cloud/wms/rest/order/check/by/express/no',
      params: data,
    })
      .then((res) => {
        if (res.data) {
          this.productData = res.data;
          this.gridModel.rows = (res.data?.details || []).map((item) => ({
            ...item,
            status: 0,
            checkedNum: 0,
          }));
          this.isCheckSuccess = true;
          this.checkDisabled = false;
          this.uniqueCodeRef.current?.focus();
        }
      })
      .catch((res: { data: { info: string; }; }) => {
        message.destroy();
        this.checkDisabled = true;
        this.handleError(res.data?.info || '出错了', '错误');
      });
  };

  // 扫描商品条码（唯一码）
  @action public queryUniqueCode = () => {
    const data = {
      uniqueCode: this.uniqueCode,
      wmsOrderId: this.productData.id,
    };

    this.uniqueCode = '';
    this.uniqueCodeRef.current?.focus();

    if (!data.uniqueCode) {
      this.handleError('商品条码不能为空', '请输入商品条码');
      return;
    }

    if (this.uniqueNos.includes(data.uniqueCode)) {
      this.handleError('唯一码输入重复', '错误');
      return;
    }

    request<BaseData<{ skuId: number; uniqueCodeFlag: boolean; }>>({
      url: '/api/cloud/wms/rest/order/check/by/unique',
      params: data,
    })
      .then((res) => {
        this.isCheckSuccess = true;
        this.handleUpdateTable(res.data, data.uniqueCode);
      })
      .catch((res: { data: { info: string; }; }) => {
        message.destroy();
        this.handleError(res.data?.info || '出错啦', '错误');
      });
  };

  // 更新表数据
  @action public handleUpdateTable = (data, value: string) => {
    const { rows } = this.gridModel;
    const {
      skuId,
      uniqueCodeFlag,
    } = data;
    const index = _.findIndex(rows, [
      'skuId',
      skuId,
    ]);
    if (index < 0) {
      this.handleError('条码错误', '错误');
      return;
    }

    this.currentData = rows[index] || {};
    if ((this.currentData.checkedNum === this.currentData.num) && this.currentData.checkedNum > 0) {
      this.handleError('已验货', '已验货');
      return;
    }

    rows[index].checkedNum += 1;

    if (uniqueCodeFlag) {
      this.uniqueNos = [
        ...this.uniqueNos,
        value,
      ];
    } else {
      this.notUniqueNos = this.notUniqueNos.includes(value) ? this.notUniqueNos : [
        ...this.notUniqueNos,
        value,
      ];
    }

    if (this.currentData.checkedNum === this.currentData.num) {
      rows[index].status = 2;
      const statusList = rows.filter((item) => item.status === 2);
      const len = statusList.length;
      getAndPlayVoice(len.toString());
      if (len === rows.length) {
        this.queryCheckPass();
      }
    } else {
      rows[index].status = 1;
    }
  };

  @observable public checkPassData: CheckPassData = {};

  // 验货通过
  @action public queryCheckPass = () => {
    const params = {
      uniqueNos: [
        ...this.uniqueNos,
        ...this.notUniqueNos,
      ],
      wmsOrderDetailIds: this.productData.details?.map((item) => item.id) || [],
      wmsOrderId: this.productData.id,
    };

    this.resetData();
    request<BaseData<CheckPassData>>({
      method: 'post',
      url: '/api/cloud/wms/rest/order/check/by/pass',
      data: params,
    })
      .then((info) => {
        this.checkPassData = info.data || {};

        if (this.resetOrder > 0) {
          this.resetOrder -= 1;
        }

        if (info?.data?.voice) {
          this.handleError(info.data.voice, info.data.voice);
        } else {
          playVoice(voiceData['通过']);
        }
      })
      .catch(() => {
        message.destroy();
        this.handleError('订单不是可验货状态', '错误');
      });
  };

  @action public resetData = (): void => {
    this.gridModel.rows = [];
    this.productData = {};
    this.isCheckSuccess = true;
    this.courierRef.current?.focus();
    this.currentData = {};
    this.checkDisabled = true;
    this.uniqueNos = [];
    this.notUniqueNos = [];
    this.checkPassData = {};
    this.courierNo = '';
    this.uniqueCode = '';
  };

  @action public handleError = (errorText: string, voText: string): void => {
    this.isCheckSuccess = false;
    this.errorInfo = errorText;
    if (voiceData[voText]) {
      playVoice(voiceData[voText]);
    } else {
      getAndPlayVoice(voText);
    }
  };
}
