import { Input, Row, Col, Typography } from 'antd';
import { egeniePcTheme, getStaticResourceUrl, Image } from 'egenie-common';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { Store } from './store';
import { ScanWrapper } from '../../utils';
import _ from 'lodash';
import { PlusServiceCellContainer, PlusServiceImgContainer } from './plusServiceImgContainer/plusServiceImgContainer';

@observer
class Left extends React.Component<{ store: Store; }> {
  render() {
    const {
      gridModel,
      checkDisabled,
      isCheckSuccess,
      errorInfo,
      queryTrackingNumber,
      queryUniqueCode,
      uniqueCode,
      handleUniqueCodeChange,
      uniqueCodeRef,
      courierNo,
      handleCourierNoChange,
      courierRef,
      resetOrder,
      displayCourierNo,
    } = this.props.store;

    const { rows = []} = gridModel;
    const isCheckedDetails = rows.filter((item) => item.status === 2);
    return (
      <div className={styles.leftContainer}>
        <h2 style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] }}>
          待验货订单
        </h2>
        <div className={styles.countContainer}>
          <span className={styles.currentCount}>
            {resetOrder}
          </span>
        </div>
        <h2 style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] }}>
          请扫描快递单号
        </h2>
        <Input
          autoComplete="off"
          autoFocus
          onChange={handleCourierNoChange}
          onPressEnter={queryTrackingNumber}
          placeholder="请扫描快递单号"
          ref={courierRef}
          value={courierNo}
        />
        <h2 style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] }}>
          请扫描商品条码
        </h2>
        <Input
          autoComplete="off"
          disabled={checkDisabled}
          id="productCode"
          onChange={handleUniqueCodeChange}
          onPressEnter={queryUniqueCode}
          placeholder="商品唯一码/sku编码/条形码"
          ref={uniqueCodeRef}
          value={uniqueCode}
        />
        <div className={isCheckSuccess ? styles.checkProgress : styles.checkError}>
          {isCheckSuccess ? `验货进度${isCheckedDetails?.length || 0}/${rows?.length || 0}` : errorInfo}
        </div>
        <div className={styles.waybillCode}>
          <Typography.Text
            copyable={Boolean(displayCourierNo)}
            title={displayCourierNo || ''}
          >
            {displayCourierNo || ''}
          </Typography.Text>
        </div>
      </div>
    );
  }
}

@observer
class Right extends React.Component<{ store: Store; }> {
  render() {
    const {
      gridModel,
      productData,
      currentData,
      checkPassData,
    } = this.props.store;
    const data: Array<{ name: string; value: string | number; }> = [
      {
        name: '店铺名称',
        value: productData.shopName,
      },
      {
        name: '快递公司',
        value: productData.courierName,
      },
      {
        name: '快递单号',
        value: productData.waybillCode,
      },
      {
        name: '买家留言',
        value: productData.buyerMemo,
      },
      {
        name: '客服备注',
        value: productData.sellerMemo,
      },
    ];

    return (
      <div className={styles.rightContainer}>
        <Row
          gutter={[
            20,
            20,
          ]}
          style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] }}
        >
          <Col span={8}>
            <div className={styles.skuDetailContainer}>
              <Typography.Title
                ellipsis={{ rows: 2 }}
                level={1}
                title={currentData.skuNo || ''}
              >
                {currentData.skuNo}
              </Typography.Title>
              <Typography.Title
                ellipsis
                level={1}
                title={`${currentData.color || ''} ${currentData.size}`}
              >
                {currentData.color}
                &nbsp;
                {currentData.size}
              </Typography.Title>
              <div
                className="egenie-common-img-fixed-shape"
                style={{
                  height: 400,
                  backgroundImage: `url(${currentData.pic ? currentData?.pic : getStaticResourceUrl('pc/ts/egenie-cloud-wms/others/qualityTestEmpty.png')})`,
                }}
              />
            </div>
          </Col>
          <Col span={8}>
            <PlusServiceImgContainer
              imgHeight={400}
              src={checkPassData?.changePackagePic}
              style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] }}
              title="包材"
            />
            <PlusServiceCellContainer
              style={{ height: 88 }}
              value={checkPassData?.changePackageTip}
            />
          </Col>
          <Col span={8}>
            <PlusServiceImgContainer
              imgHeight={400}
              src={checkPassData?.goodCardPic}
              style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] }}
              title="好评卡"
            />
            <PlusServiceCellContainer
              style={{ height: 88 }}
              value={checkPassData?.goodCardTip}
            />
          </Col>
        </Row>
        <div className={styles.detailContainer}>
          <Row
            gutter={[
              16,
              16,
            ]}
          >
            {
              data.map((item) => (
                <Col
                  key={item.name}
                  span={6}
                >
                  <Typography.Text
                    ellipsis
                    style={{ maxWidth: '100%' }}
                    title={_.toString(item.value)}
                  >
                    {item.name}
                    :
                    <span className={styles.detailValue}>
                      {item.value}
                    </span>
                  </Typography.Text>
                </Col>
              ))
            }
          </Row>
        </div>
        <div style={{ height: 234 }}>
          <EgGrid store={gridModel}/>
        </div>
      </div>
    );
  }
}

const store = new Store();
export default function() {
  return (
    <ScanWrapper
      leftChildren={<Left store={store}/>}
      leftTitle="验货"
      leftWidth={400}
      rightChildren={<Right store={store}/>}
      rightTitle="扫描结果"
    />
  );
}
